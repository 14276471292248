import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Layout = ({ children, location, title }) => {
  let displayBack = true
  if (
    location &&
    (location.pathname === "/home/" || location.pathname === "/")
  ) {
    displayBack = false
  }

  return (
    <>
      {displayBack ? (
        <nav className="mb-3">
          <Link to="/home/" className="position-relative-zindex nav-a">
            BACK
          </Link>
        </nav>
      ) : null}
      <h1 className="position-relative-zindex">{title}</h1>
      {children}
      {displayBack ? (
        <nav className="mt-3">
          <Link to="/home/" className="position-relative-zindex nav-a">
            BACK
          </Link>
        </nav>
      ) : null}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
